// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.beta-notice {
  background-color: #5cb85c;
  padding: 5px;
  margin-bottom: 0;
  color: white;
  font-weight: bold;
}

html {
  max-width: 100%;
  overflow-x: hidden;

}

body {
  background-color: #fafafa !important;
  //background-color: white !important;
  padding-right: 0 !important;
  font-family: 'Poppins', sans-serif;
}

.body-content {
  padding-top: 90px;
}

#back_to_top {
  border-radius: 15px;
  box-shadow: 0 0 5px white;
  position: fixed;
  bottom: 15px;
  width: 150px;
  z-index: 9999999;
  text-align: center;
  //right: 15px;
  padding: 15px;
  left: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  transition: 0.25s all;
  margin: 10px -75px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

nav {
  //background-color: white !important;
  //background-color: #F9F9F9 !important;
}

.summarized {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#left_nav {
  //margin-right: -30px;
}

.panel {

}

.store_banner {
  background-position: center center !important;
  background-size: cover !important;
  padding: 15% 50%;

}

.photo-square {
  padding: 0 0 100% 0;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
}

.profile-photo {
  border-radius: 50%;
  border: 2px solid white;
}

.product_image_description {
  position: absolute;
  left: 0;
  right: 0;
  -webkit-box-pack: center;
  margin-left: 15px;
  margin-right: 15px;
  //width: 100%;
  width: calc(100% - 30px);
  //height: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.65);
  opacity: 0;
  bottom: 0;
  color: white;
  transition: 0.25s all;
  height: calc(100%);
  cursor: pointer;

  #product-name {
    font-weight: bold;
    font-size: 110%;
  }

  &:hover {
    opacity: 1;
  }
}

footer nav {
  background-color: transparent !important;
  padding: 10px 0;
}

.clear-border-background {
  border-radius: 0 !important;
  background-color: transparent !important;
  border: 0;
  box-shadow: none;
}

.separator {
  padding-bottom: 15px;
}

.profile-picture-holder {
  padding: 0 20%;
  margin: -6px -10px -6px 0;
}

footer {
  margin-top: 30px;
  margin-bottom: 15px;
  a {
    color: #003569 !important;
    text-decoration: none !important;
    font-weight: bold !important;
    margin-right: 15px;
  }
  a:last-child {
    margin-right: 0 !important;
  }
  span {
    color: black !important;
    text-decoration: none !important;
    font-weight: bold !important;
  }
  .col-sm-3 {
    text-align: right;
  }

}

.navbar-nav {
  margin: 0 -15px;
}

nav.navbar.navbar-default {
  background-color: white;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 0;

  .navbar-header, .navbar-nav {
    padding: 10px 0;
  }

  #site_logo {
    position: absolute;
    height: 50px;
    margin-top: -15px;
  }

}

.section-title {
  text-transform: uppercase;
  font-weight: bold;
  color: #003569 !important;
  cursor: default;
}

#profile-picture-username {
  margin-top: 15px;
  padding: 0 15px;
  margin-bottom: 10px;

  #username-display {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.5em;
  }

  #profile-picture {
    height: 80px;
    width: 80px;
    display: inline-block;
    vertical-align: middle;
    margin: -10px 29px -10px calc(100% - 30px - 66.666% - 70px);
    background-size: cover !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    cursor: pointer;
  }

}

.sm-screen {
  display: none;
}

h1 {
  font-size: 24px;

}

ol.lower-alpha {
  list-style-type: lower-alpha;
}

ol.lower-roman {
  list-style-type: lower-roman;
}

textarea {
  max-width: 100%;
  min-height: 150px;
}

.help-block {
  margin-bottom: 0 !important;
}

#address span {
  display: block;
}

.product_price {
  //background-color:#3c763d;
  //color:white;
  //padding:5px;
  //border:1px solid #d0d0d0;
  //background-color:white;
  font-weight: bold;
  //font-size: 24px;
  //span {
  //  font-size: 16px;
  //  font-weight:bold;
  //}
}

.product_not_available {
  background-color: #3c763d;
  color: white;
  padding: 5px;
}

#large_preview.product-image {
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
}

h1.product-name {
  font-weight: bold;
  font-size: 225%;
}

#country-flag {
  height: 30px;
  margin-left: 5px;
  margin-top: -10px;
  margin-bottom: -5px;
}

.product_image_description {
  ///* Internet Explorer 10 */
  //display: -ms-flexbox;
  //-ms-flex-pack: center;
  //-ms-flex-align: center;
  //
  ///* Firefox */
  //display: -moz-box;
  //-moz-box-pack: center;
  //-moz-box-align: center;
  //
  ///* Safari, Opera, and Chrome */
  //display: -webkit-box;
  //-webkit-box-pack: center;
  //-webkit-box-align: center;

  .desc_holder {
    text-align: center;
    //font-size: 1.25em;
    //padding-top: 30%;
    //max-width: 95%;
    margin: 2.5%;

    span {
    }

    p {
      //margin-bottom: 0;
    }

    #product-name {
      display: block;
      //font-weight: bold;
      //font-size: 110%;
      width: 100%;
      //text-overflow: ellipsis;
      //overflow: hidden;
      //white-space: nowrap;

    }

    #price {
      small {
        font-size: 80%;
      }
      //font-size: 90%;
    }
  }

}

.carousel-control {
  background: transparent !important;
}

#product-image-upload-list {
  margin-top: -12px;
  .col-xs-4 {
    margin-top: 12px;
  }
  .col-xs-4:nth-child(3n+1) {
    padding-right: 3px;
    padding-left: 15px;
  }
  .col-xs-4:nth-child(3n+2) {
    padding-right: 9px;
    padding-left: 9px;
  }
  .col-xs-4:nth-child(3n+3) {
    padding-right: 15px;
    padding-left: 3px;
  }

}

.hidden {
  margin: 0 !important;
  padding: 0 !important;
}

#store-contact-information {
  background-color: #f0f0f0;
  padding: 20px 30%;
  margin-bottom: 30px;
  margin-left: -30% !important;
  margin-right: -30% !important;
}

.no-side-margin {
  margin-left: -15px !important;
  margin-right: -15px !important;
}

#contact-information {
  img {
    height: 24px;
    margin: 5px 5px 5px 0;
    border-radius: 50%;
    display: inline-block;
    vertical-align: top;
  }
  .info-title {
    font-weight: 700 !important;
  }

  .info-detail {
    max-width: calc(100% - 35px);
    display: inline-block;
    vertical-align: top;
  }

  #social-list {
    span {
      margin-right: 5px;
      display: inline-block;
    }
    a {
      text-decoration: none;
    }
  }

}

#store-badges {
  display: none;
  .badge {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    padding: 50% 50%;
  }
}

.page-title {
  //margin-bottom: 20px;
  font-weight: bold;
}

.store_banner_upload_loading {
  display: -webkit-box;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  vertical-align: middle;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  color: black;
  position: absolute;
  top: 0;
  left: 15px;
  width: calc(100% - 30px);

  i {
    zoom: 1.25;
  }
}

#url-message {
  color: #a94442 !important;
}

.product-upload-loading {
  display: -webkit-box;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  vertical-align: middle;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  color: black;
  position: absolute;
  width: calc(100% - 18px);
}

#construction_notice {
  background: url("/img/construction.svg");
  padding-bottom: 60%;
  width: 70%;
  margin: 0 15%;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
}

#landing-form {
  //color: white;
  .text-danger {
    color: red !important;
  }
  input {
    text-align: center;
  }

  .panel-default {
    border-color: transparent !important;
  }

  .panel {
    //background-color: rgba(0, 0, 0, 0.75);
    background-color: #f0f0f0;
    padding: 10px;
    //zoom: 0.85;
  }
  .panel-heading {
    //color: white !important;
    color: #003569;
    font-weight: bold;
    background-color: transparent !important;
    border-color: transparent !important;
    font-size: 1.25em;

  }
}

#market_place {

  padding-top: 15px;
  .item-holder {

    a {
      text-decoration: none;
    }

    .background {
      background-color: white;
      height: 100%;
      position: absolute;
      width: calc(100% - 30px);
      z-index: -1;
      //box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
      border: 1px solid #efefef;
    }
    //padding: 0;
    margin-bottom: 15px;
    //background-color: white;
    //box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    border: 0;

    .product-image {
      margin: 0.5px;
      flex: 5;
      width: 100%;
      .photo-square {
        width: calc(65% - 38px);
        padding-bottom: calc(65% - 38px) !important;
      }
    }
  }

  .product-detail {
    margin-left: calc(-35% - 27px);
    right: 15px;
    flex: 3;
    position: absolute;
    height: 100%;
    transition: 0.25s all;
    border-top: 1px solid #efefef;
    zoom: 1;
    width: calc(35% + 27px);
    border-left: 1px solid #efefef;

    .product-name {
      font-weight: bold;
      font-size: 110%;
      padding: 15px;
      padding-bottom: 0;
      margin-bottom: 7px;
    }

    .product-desc {
      font-size: 90%;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 15px;
      height: calc(100% - 50px - 156px);
      margin-bottom: 15px;
    }

    .product-price {
      margin-top: 15px;
      margin-left: 15px;
    }

    .load-more {
      border-top: 1px solid #efefef;
      padding-top: 13px;
    }
  }

  .shop-header-sm {

    padding: 15px;
    border-bottom: 1px solid #efefef;
    #profile-picture {
      margin-left: 5px;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      display: inline-block;
      background-size: cover !important;
      background-repeat: no-repeat !important;
      background-position: center center !important;
      vertical-align: middle;
    }

    #store-name {
      display: inline-block;
      font-weight: bold;
      vertical-align: top;
      margin-top: 14px;
      margin-left: 5px;
      width: calc(100% - 35px - 29px);
      word-break: break-all;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: black;
    }

  }
}

.home-page-row {
  //margin-bottom: 20px;
  //padding:20px 0;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 30%;
  padding-right: 30%;
  margin-left: calc(-30% - 15px) !important;
  margin-right: calc(-30% - 15px) !important;

  .img-logo {
    height: 100px;
    width: 100px;
    margin: 0 auto;
  }
  .home-page-title {
    font-weight: bold;
    padding-bottom: 10px;
  }

  .home-page-content {

  }

  .flex-box {
    display: table;
    height: 100px;
    padding: auto 0;

    .home-page-title {
      display: table-cell;
      vertical-align: bottom;
    }

    .home-page-content {
      display: table-row;
    }
  }
}

.home-page-row.striped {
  background-color: #f0f0f0;

}

.errors_image {
  padding-bottom: 60%;
  width: 70%;
  margin: 0 15%;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
}

.flex-parent {
  display: flex;
  flex-direction: row;
  -ms-flex-align: start;
}

#store-detail {
  margin-bottom: 20px;
  #store-description.text-center {
    text-align: justify !important;
  }
}

#contact-data > .col-sm-7 {
  height: 34px;
}

.title-helper {
  font-size: 95%;
  text-align: justify;
  &.note {
    color: grey;
    &:before {
      content: '* ';
    }
  }
}

#profile-picture-holder {
  #profile-picture {
    padding-bottom: 100%;
    border-radius: 50%;
    background-size: cover !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    //box-shadow:0 0 5px #d0d0d0;
  }
  .profile_pic_upload_loading {
    display: -webkit-box;
    -webkit-box-pack: center;
    -webkit-box-align: center;
    vertical-align: middle;
    height: 100%;
    width: calc(100% - 30px);
    position: absolute;
    background-color: rgba(255, 255, 255, 0.75);
    color: black;
    border-radius: 50%;
  }
}

.saving-info {
  display: none;
  margin-left: 5px;
}

div#section-navigator {
  .list-group-item {
    margin-top: 13.5px;
    display: inline-block;
    color: grey;
    background-color: #f0f0f0;
    border: 0;
    border-radius: 0;
    padding: 10px 25px;
    transition: 0.25s all;
  }

  .list-group-item.active {
    color: black;
    border-radius: 0 !important;
    font-weight: bold;
    background-color: #e0e0e0;
  }

  .list-group-item:hover {
    color: black;
    font-weight: bold;
    background-color: #e0e0e0;
    border-radius: 0 !important;
  }

  i {
    display: none;
  }

}

.setting-page-title {
  padding-bottom: 15px;
  @media(min-width: 768px) {
    .page-title {
      text-align: right;
    }

  }
}

.setting-section {
  margin-bottom: 35px;
  padding-bottom: 30px;;
  border-bottom: 1px solid #f0f0f0;
}

.setting-section:last-child {
  border-bottom: 0;
}

.message-box {
  margin-bottom: 30px;
  i {
    zoom: 2.5;
    vertical-align: middle;
  }
}

.dropdown-header {
  color: #003569;
  font-weight: bold;
  font-size: 10px;
  margin-left: -5px;
  text-transform: uppercase;
}

.out-of-stock {
  background: url("/img/watermark_bg.svg");
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  position: absolute;
  z-index: 2;
  width: 80px;
  right: 10px;
  top: 10px;
  //background-color: #003569 !important;
  color: white;
  height: 80px;
  font-size: 10px;
  vertical-align: middle;
  display: -webkit-box;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  text-align: center;
  border-radius: 50%;
}

//.landing-page {
//
//  color: white;
//  footer {
//    .col-sm-9, .col-sm-3 {
//      display: block;
//      width: 100%;
//      text-align: center;
//    }
//
//  }
//
//  body {
//    background-color: white !important;
//  }
//}

.text-white {
  color: white !important;
}

.site-header {
  //width:100%;

  padding: 12%;
  background: url('/img/site-heading.svg');
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}

.vertical-middle {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex;

  -webkit-box-flex: 1; /* OLD - iOS 6-, Safari 3.1-6 */
  -moz-box-flex: 1; /* OLD - Firefox 19- */
  -webkit-flex: 1; /* Chrome */
  -ms-flex: 1; /* IE 10 */
  flex: 1;

  flex-direction: column;
  justify-content: center;
}

.product-product-information {
  //padding: 20px;
  //background-color: white;
  //border:1px solid #e0e0e0;
  //box-shadow: 0 1px 1px 0 rgba(0,0,0,.06),0 2px 5px 0 rgba(0,0,0,.2);
  //border-radius:5px;
  //margin-bottom: 20px;
  padding: 30px 0 15px 0;
  padding-right: 15px;

  //box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  //border-color: #e5e6e9 #dfe0e4 #d0d1d5
}

.product-product-background {
  position: absolute;
  //background-color: #f0f0f0;
  height: calc(100% + 0px);
  width: 160%;
  z-index: -1;
  margin: 0 -30%;
  //border-top:1px solid #d0d0d0;

}

#other_product_list {
  h4 {
    padding-top: 20px;
  }
}

#product-image-carousel {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
}

.social-media-sharing {
  //background-color: white;
  //padding: 20px;
  //box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  span {
    margin-right: 5px;
  }
  a {

    text-decoration: none;
    img {
      height: 35px;
    }
    img:hover {
      -webkit-filter: grayscale(0%);
      -webkit-transition: .25s ease-in-out;
      -moz-filter: grayscale(0%);
      -moz-transition: .25s ease-in-out;
      -o-filter: grayscale(0%);
      -o-transition: .25s ease-in-out;
    }

    img {
      -webkit-filter: grayscale(100%);
      -webkit-transition: .25s ease-in-out;
      -moz-filter: grayscale(100%);
      -moz-transition: .25s ease-in-out;
      -o-filter: grayscale(100%);
      -o-transition: .25s ease-in-out;
    }
  }

}

#product-image-list {
  .product-image {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);

  }
}

@media(min-width: 1200px) {
  #store_page_banner {
    .store_banner {
      margin-top: -20px;
      margin-left: calc(-16.666666666667% - 23px - 8.33333333%);
      width: calc(100% + 16.66666667% + 22.5px + 8.333333% + 16.66666667% + 23.5px + 8.333333%);
      padding-top: 31.6%;

    }

  }
}

@media(max-width: 1199px) and (min-width: 992px) {
  #store_page_banner {
    .store_banner {
      margin-top: -20px;
      margin-left: calc(-32px - 8.33333333%);
      width: calc(100% + 32.5px + 8.333333% + 32.5px + 8.333333%);
      padding-top: 22.4%;
    }

  }
}

.store-profile-pic {
  div {
    padding-bottom: calc(100% - 16px);
    margin-top: -75px;
    border: 8px solid #fafafa;
  }
}

.pace {
  -webkit-pointer-events: none;
  pointer-events: none;

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  background: #29d;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
}

#marketplace {
  .market_product_image {
    background-size: cover !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    padding-bottom: 100%;
    margin-bottom: 30px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    border: 0;
  }

  .market_store_image {
    background-size: cover !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    padding: calc(25% - 7.5px) 50%;
    margin-bottom: 30px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    border: 0;
  }

  .market_product_image_large {
    background-size: cover !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    padding-bottom: 100%;
    margin-bottom: 30px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    border: 0;
    border-radius: 50%;
    + .market_desc {
      border-radius: 50% !important;
    }

  }

  .market_desc {
    position: absolute;
    left: 0;
    right: 0;
    -webkit-box-pack: center;
    margin-left: 15px;
    margin-right: 15px;
    width: calc(100% - 30px);
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    bottom: 15px;
    color: white;
    transition: 0.25s all;
    height: calc(100% - 30px);
    cursor: pointer;

    &:hover {
      opacity: 1 !important;
    }
  }

  .market_desc_holder {
    text-align: center;
    margin: 2.5%;

    #market_item_name {
      display: block;
      font-weight: bold;
      font-size: 125%;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

}

#product_preview {
  //background-color: #f0f0f0;
  //padding:30px calc(30% - 15px);
  //margin:0 -30%;
  //border-bottom:1px solid #d0d0d0;
  //border-top:1px solid #d0d0d0;
  //margin-top: 15px;

  .preview-large {
    transition: 0.25s all;
    margin-bottom: 30px;
    border: 1px solid #eee;
    &:after {
      content: '';
      display: inline-block;
      width: calc(100% - 30px);
      height: 10px;
      background: url('/img/holder-shadow.png') no-repeat 0 center;
      background-size: 100% auto;
      position: absolute;
      left: 15px;
      bottom: 20px;

      @media(max-width: 767px) {
        bottom: 5px;
      }
    }

  }
  .preview-small {
    &.active {
      border: 1px solid grey;
      @media(max-width: 767px) {
        border: 0 !important;
      }
    }
    border: 1px solid #eee;
    margin-bottom: 15px;
    &:after {
      content: '';
      display: inline-block;
      width: calc(100% - 30px);
      height: 10px;
      background: url('/img/holder-shadow.png') no-repeat 0 center;
      background-size: 100% auto;
      position: absolute;
      left: 15px;
      bottom: 10px;

      @media(max-width: 767px) {
        bottom: 5px;
      }
    }

  }
}

#product_preview_large {
  margin-bottom: 15px;
  .product-image {
    //border: 1px solid;
    //border-color: #e5e6e9 #dfe0e4 #d0d1d5;
    //border-radius: 5px;
  }

}

#product_image_selection {
  .col-sm-6 {
    margin-bottom: 30px;
  }
  .product-image {
    //border: 1px solid;
    //border-color: #e5e6e9 #dfe0e4 #d0d1d5;
    //border-radius: 5px;

    &.active {
      outline: 2px solid #337ab7;
    }
  }

}

#product_header_description {
  margin-bottom: 20px;
  span {
    height: 25px;
    width: 25px;
    margin-bottom: -7.5px;
    margin-left: 5px;
    margin-top: -2.5px;
    display: inline-block;
    background-size: cover !important;
    background-position: center center !important;
    //background-repeat: no-repeat !important;
    border-radius: 50%;
    border: 1px solid #d0d0d0;
  }
  a {
    text-decoration: none;
  }
}

#product_product_detail {
  padding: 0 calc(30% - 15px);
  margin: 30px -30%;
  margin-bottom: 15px;
  padding-bottom: 15px;
  background-color: #f0f0f0;
}

.product-product-pricing {
  padding-top: 30px;
}

.contact-seller-for-purchase {
  font-size: 12px;
  color: #003569 !important;
  font-weight: bold;
}

#landing_column {
  .site-header {
    margin: 20px 0;
  }

  height: 620px;
  //border:1px solid;
  //border:0;
  //border-color:#e5e6e9 #dfe0e4 #d0d1d5;
  //border:1px solid #e0e0e0;

  .cover-background {
    //background-size: cover !important;
    position: absolute;
    z-index: -1;
    height: 620px;
    width: 100%;
    top: 0;
    right: 0;
    padding-right: 50px;
    background-position: center right !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    //opacity: 0.75;
  }

  #login_form, #register_form {
    //max-width: 350px;
    margin: 0 auto;
    height: 498px;

    .panel.panel-default {
      height: 100%;
      border-radius: 0;
      //background-color:transparent;
      box-shadow: none;
    }
  }

}

#landing_page_heading {
  margin-top: 20px;
  margin-bottom: 20px;
}

.store_page {
  #store-banner {
    border-bottom: 1px solid #d0d0d0;
  }

  .store_section_title {
    font-weight: bold;
    text-transform: uppercase;
    //font-size:90%;
    //background-color:#f0f0f0;
    color: grey;
    padding: 7px 10px;
    text-align: center;
    border-top: 1px solid #d0d0d0;
    border-bottom: 1px solid #d0d0d0;
    //background-color:#f0f0f0;

  }

  #store_store_info {

    //background-color:#f0f0f0;
    padding-bottom: 20px;
    margin-top: -14px;
    #store_profile_picture {
      padding-bottom: calc(100% - 14px);
      background-position: center center !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;
      margin-top: -45%;
      border: 7px solid #fafafa;
      margin-bottom: -10px;
      box-shadow: 0 0 5px grey;
    }

    #store_store_title {
      margin-bottom: 2px;
    }

    #store_store_url {
      font-size: 80%;
    }

    #store_store_description {
      font-size: 95%;
      //background-color:#f0f0f0;
      //padding:10px;
    }

    .store_contact_channel {
      font-size: 90%;
      margin-bottom: 5px;
      img {
        vertical-align: middle;
        display: inline-block;
        height: 25px;
        //margin-right:5px;
      }

      span {
        height: 25px;
        padding: 3px 10px;
        display: inline-block;
        font-weight: bold;
        vertical-align: middle;
        width: calc(100% - 27px);
        margin-left: -3px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &.whatsapp-color {
          background-color: #2C910A;
          color: white;
        }

        &.facebook-color {

          color: white;
        }

        &.youtube-color {
          color: white;
          background-color: #AF2210;
        }

        &.wechat-color {
          color: white;
          background-color: #049E07;
        }

        &.twitter-color {
          color: white;
          background-color: #16A0C1;
        }

        &.phone-color {
          color: white;
          background-color: #4E3068;
        }

      }
    }
  }

  #store_item_listing {

    .no-result {
      padding: 7px 15px;
    }
  }

}

#product-listing-lg {
  .product-image {
    //margin-bottom: 15px;
    //margin-top: 15px;

  }
  .product-holder {
    margin-bottom: 30px;
  }

}

.big_section_title {
  margin-bottom: 10px;
  text-align: center;
  &:before {
    content: "";
    position: absolute;
    top: .625em;
    left: 15px;
    width: calc(100% - 30px);
    height: 3px;
    background: grey;
    border-bottom: 1px solid #fff;
    z-index: -5;
  }
  span {
    background: #fafafa;
    position: relative;
    z-index: 1;
    font-weight: bold;
    //text-transform: uppercase;
    color: grey;
    //padding: 7px 10px;
    padding: 10px 10px;
    font-size: 125%;
    //text-align: center;
    //border-top: 1px solid #d0d0d0;
    //border-bottom: 2px solid #d0d0d0;
  }

}

#auth_page {
  .sub_heading {
    margin-top: -13px;
    color: grey;
    margin-bottom: 25px;
  }

  #login_panel {
    background-color: white;
    padding: 20px 35px;
    border: 1px solid #f0f0f0;
    max-width: 450px;
    margin: 0 auto;
    //border-radius: 5px;
    .subtitle {
      font-size: 125%;
      margin-bottom: 15px;
      font-weight: bold;
      //background-color:#fafafa;
      padding: 10px 0;
    }

    .section-separator {
      hr {
        border: 0;
        border-top: 1px solid #ccc;

      }
      span {
        margin-top: -33px;
        margin-bottom: 25px;
        display: block;
        text-align: center;
        background-color: white;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        font-size: 125%;
        font-weight: bold;
        //background-color:#fafafa;
      }
    }
  }
}

.social-status-btn {
  display: inline-block;
  padding: 7px;
  font-weight: bold;
}

.table-responsive {
  overflow-x: inherit !important;
}

.note-editor {
  .modal-body {
    .form-group {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
  .modal.in {
    padding-right: 0 !important;
  }
}

#landing_footer {
  background-color: #1d164e;
  a, span {
    color: white !important;
  }
}

#store_fashemag_article_list {
  li {
    margin-bottom: 7px;
  }
  a {
    text-decoration: none;
  }
  #pic {
    display: inline-block;
    div {
      background-size: cover !important;
      background-position: center center !important;
      background-repeat: no-repeat !important;
      height: 75px;
      width: 75px;

    }
  }

  #content {
    #title {
      color: black;
      font-weight: bold;
    }
    #subtitle {
      color: grey;
      font-size: 90%;
      margin-top: -5px;
      height: 20px;
    }
    display: inline-block;
    margin-left: 5px;
    width: calc(100% - 75px - 10px);
    vertical-align: top;
    height: 65px;
    margin-top: 10px;

  }
}

nav.navbar.navbar-default {
  li {

    a {
      color: grey;
      transition: 0.25s all;

      &:hover {
        color: black;
        background-color: #e0e0e0;
      }
    }
  }
}

.card {
  //padding:20px;
  //background-color:#ffffff;
  //border:1px solid #f0f0f0;
  //background-color:#ececec;
  margin-bottom: 30px;
  //border-radius:5px;
  //box-shadow:0 1px 1px #e0e0e0;
  //border: 1px solid;
  //border-color: #e5e6e9 #dfe0e4 #d0d1d5;
  //border-color:#efefef;

  @media(max-width: 767px) {
    //&:nth-child(2n+1) {
    //  background-color:#f5f5f5;
    //}
    padding: 15px 15px;
    background-color: transparent;

    border: 0 !important;
    margin: 0 -15px;
    border-radius: 0;
    .card-title {
      //margin-bottom:0 !important;
      //border-bottom:1px solid grey !important;
    }
  }

  .card-toolbar {
    background-color: #f0f0f0;
    padding: 15px;
    //padding-bottom:0;
    .btn {
      border-radius: 0;
      margin-bottom: 15px;
    }
    margin-bottom: 15px;
  }

  .panel {
    background-color: transparent;
    border: 0;
    .panel-heading {
      padding: 0 0 10px;
      background-color: transparent;
      border: 0;
      box-shadow: none;
    }
    .panel-body {
      border: 1px solid #ddd;
      background-color: white;
      .table-responsive {
        padding: 15px 0;
      }
    }
  }

  .card-title {
    //color:#003569 !important;
    font-size: 150%;
    font-weight: bold;

    margin-bottom: 15px;
    margin-top: 20px;
    //padding-top:20px;

    color: grey;

    &.underlined {
      border-bottom: 1px solid #efefef;
      padding-bottom: 10px;
    }

    &.big {
      font-size: 180%;
    }

  }

}

.card-type-title {
  font-size: 150%;
  font-weight: bold;
  border-bottom: 1px solid #efefef;
  padding-bottom: 10px;
  margin-bottom: 15px;
  color: grey;

  @media(max-width: 767px) {
    margin-top: 15px;
    //margin-bottom:0 !important;
    //border-bottom:0 !important;
  }
}

#social-media-sharing-vertical {
  display: flex;
  flex-direction: column;
  a {
    flex: 1;
    margin-bottom: 10px;
    img {
      width: 100%;
    }
  }
}

.address-list-holder {
  margin-bottom: 15px;

  .address-type {
    color: grey;
  }
  .address {
    margin-bottom: 15px;
  }
}

#load_next_page {
  text-align: center;
  font-weight: bold;
  padding: 20px 15px;
  transition: 0.25s all;
  border: 1px solid #f0f0f0;
  background-color: #f0f0f0;
  margin-bottom: 15px;
  color: grey;
  &:hover {
    background-color: #d0d0d0;
  }
  @media(max-width: 767px) {
    padding: 7px 15px !important;
  }
}

.sharethis {
  margin-bottom: 15px;
  &.card {
    margin-bottom: 0;
  }
  span {
    margin-bottom: 7px;
  }
}

#display_price {
  .symbol {
    font-size: 150%;
  }
  .number {
    font-size: 225%;
  }

  font-weight: bold;
  margin-bottom: 0;
  color: green;
}

#ori_price {
  color: grey;
}

.location-map {
  margin-bottom: 15px;
  border: 1px solid #f0f0f0;
}

//.branch_address {
//  padding: 15px;
//  background-color: #f0f0f0;
//  word-break: normal;
//}

.product-list {
  .product {
    transition: 0.25s all;
    border: 1px solid #eee;
    margin-bottom: 30px;

    &:after {
      content: '';
      display: inline-block;
      width: calc(100% - 30px);
      height: 10px;
      background: url('/img/holder-shadow.png') no-repeat 0 center;
      background-size: 100% auto;
      position: absolute;
      left: 15px;
      bottom: 20px;

      @media(max-width: 767px) {
        bottom: 5px;
      }
    }
    &:hover {
      border: 1px solid grey;
      .product-image {
        background-size: 125% !important;
      }
    }
    .product-image-holder {
      overflow: hidden;
      .product-image {
        transition: 0.75s all;
        padding-top: 100%;
        background-size: cover !important;
        background-position: center !important;

        &:hover {
          transform: scale(1.05);
        }
      }
    }

    .product-detail {
      @media(max-width: 767px) {
        margin-left: -30px;
        padding: 10px 15px;
      }
      padding: 15px 15px;
      .product-name {
        font-size: 120%;
        margin-bottom: 5px;
        font-weight: bold;
        color: black;
      }
      .product-description {
        color: grey;
        font-size: 90%;
        margin-bottom: 7px;
      }
      .product-pricing {
        margin-bottom: 7px;
        color: green;
        font-weight: bold;
        .currency {
          position: relative;
          line-height: 0;
          vertical-align: baseline;
          top: -0.4em;
          font-size: 80%;

        }
        .amount {
          font-size: 115%;
        }
      }
      .product-availability {
        font-size: 90%;
        color: grey;
        margin-bottom: 0;
      }

    }

    @media(max-width: 767px) {
      margin-bottom: 15px;
    }
  }
}

.article-list {
  a {
    display: block;
    text-decoration: none;
    .article {
      margin-bottom: 15px;
      .cover-photo {

        padding: 35% 50%;
        background-size: cover !important;
        background-position: center !important;
      }
      .article-title {
        font-weight: bold;
        font-size: 100%;
        color: black;
        padding: 15px 0;
      }
      .article-subtitle {
        color: grey;
      }
    }
  }

  _.article {

    @media(max-width: 767px) {
      margin-bottom: 15px;
    }

    margin-bottom: 30px;
    transition: 0.25s all;
    outline: 1px solid #eee;

    &:after {
      content: '';
      display: inline-block;
      width: calc(100% - 30px);
      height: 10px;
      background: url('/img/holder-shadow.png') no-repeat 0 center;
      background-size: 100% auto;
      position: absolute;
      left: 15px;
      bottom: 20px;

      @media(max-width: 767px) {
        bottom: 5px;
      }
    }

    &:hover {
      outline: 1px solid grey;
      .cover-photo {
        background-size: 125% !important;
      }
    }
    .cover-photo-holder {
      overflow: hidden;
      .cover-photo {
        transition: 0.75s all;
        padding: 15% 50%;
        background-size: cover !important;
        background-position: center !important;

        &:hover {
          transform: scale(1.05);
        }
      }
    }
    .article-detail {
      padding: 15px 15px;
      .article-title {
        font-weight: bold;
        font-size: 120%;
        color: black;

      }
      .article-subtitle {
        font-size: 90%;
        color: grey;

      }
    }

  }
}

#categories_select {
  .panel-title {
    a {
      &:before {
        transition: 0.5s all;
        content: "\f0d7";
        font-family: 'FontAwesome';
      }
      &.collapsed {
        &:before {
          transition: 0.5s all;
          content: "\f0da" !important;
          font-family: 'FontAwesome';
        }
      }

      &:hover {
        &:before {

        }
      }
    }
  }
}

#landing {
  .section {
    &.bg-dark {
      margin-left: -100%;
      padding-left: 100%;
      margin-right: -100%;
      padding-right: 100%;
      background-color: #FFE9F4;
    }
    @media(min-width: 768px) {
      padding-top: 50px;
      padding-bottom: 70px;
    }

    @media(max-width: 767px) {
      padding-top: 25px;
      padding-bottom: 25px;
    }

    .title {
      text-align: center;
      font-size: 225%;
      font-weight: bold;
      margin-bottom: 30px;
    }

    p.description {
      font-size: 150%;
      margin-bottom: 20px;
    }

    .big-image {
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
      transition: 0.25s all;
      &:hover {
        -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
        filter: grayscale(0%);
      }
      @media(min-width: 768px) {
        padding: 30px 100px;
      }
      @media(max-width: 767px) {
        padding: 25px;
      }
    }

    .feature {
      text-align: center;
      .subtitle {
        font-size: 175%;
      }
      .description {
        color: grey;
        margin-bottom: 20px;
      }

      .img-frame {
        border: 2pt solid #d0d0d0;
        border-radius: 5px;
      }
      margin-bottom: 30px;
    }

    #brands_list {
      padding-top: 30px;
      .brand {
        &:hover {
          .photo {
            box-shadow: 0 0 10px grey;
          }
        }
        .photo {
          transition: 0.25s all;
          border-radius: 50%;
          padding-top: 100%;
          margin-bottom: 15px;
          background-size: cover !important;
          background-position: center !important;
        }
        .name {
          font-size: 150%;
          color: black;
          font-weight: bold;
          text-align: center;
        }
        margin-bottom: 30px;
      }
    }

    #social_channels {
      a {
        font-size: 500%;
        color: grey;
        text-decoration: none;
        transition: 0.25s all;
        &:hover {
          color: black;
        }
      }
    }

    #fashemags_list {
      a {
        @media(max-width: 767px) {
          color: black;
          background-color: #f0f0f0;
        }
        font-weight: bold;
        padding: 15px;
        border: 1px solid #d0d0d0;
        //border-radius: 15px;
        text-decoration: none;
        display: block;
        transition: 0.25s all;
        color: grey;
        &:hover {
          background-color: #f0f0f0;
          //border-color:#d0d0d0;
          color: black;
        }
      }
    }
  }
}

.fashemag_viewer {
  margin-top: -20px;
  //background-color:white;
  //border:1px solid;
  //border-color:#e5e6e9 #dfe0e4 #d0d1d5;

  .header {
    .title {
      @media(max-width: 767px) {
        font-size: 180%;
      }
      margin-top: 0;
      font-weight: bold;
      font-size: 220%;
      line-height: 150%;
    }
    .subtitle {
      color: grey;
      font-size: 135%;
      margin-bottom: 15px;
    }
    .details {
      //color: grey;
      .date {
        margin-bottom: 15px;
        font-size: 90%;
        //display: inline-block;
      }
      .linked-brand {
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 15px;
        //display: inline-block;
        a {
          text-decoration: none;
          .photo {
            display: inline-block;
            width: 40px;
            height: 40px;
            margin-top: -15px;
            margin-bottom: -15px;
            border-radius: 50%;
            background-size: cover !important;
            background-position: center !important;
          }
          .name {
            display: inline-block;
          }
        }
      }

    }
  }
  .article-cover-photo-holder {

    .article-cover-photo {
      @media(max-width: 767px) {
        padding: 30% 50%;
        margin: 0 -15px 0 -15px;
      }
      padding: 15% 50%;
      background-size: cover !important;
      background-position: center !important;
      margin-bottom: 15px;
    }

  }

  .content {
    padding-top: 15px;
    margin-bottom: 30px;
    img {
      max-width: 100%;
      margin-bottom: 15px;
    }
  }
}

.note-editor {
  .row {
    .col-xs-12 {
      border: 1px dashed grey;
      padding: 5px;
      min-height: 50px;
    }
  }

  .embed-responsive {
    min-height: 50px;
    border: 1px dashed grey;
  }
}

#store_page {
  .banner-holder {
    margin-top: -20px;

    @media(min-width: 1200px) {
      margin: -20px -12% 0 -12% !important;
    }
    @media (max-width: 1199px) {
      margin: -20px -15px 0 -15px;
      width: calc(100% + 30px);
    }
    .banner {
      padding-top: 27%;
      background-size: cover !important;
      background-position: center center !important;
      border-radius: 0;
      @media(max-width: 767px) {
        padding-top: 220px;
      }
    }
  }

  .heading {
    //border:1px solid #f0f0f0;
    //background-color:#fbfbfb;
    padding: 30px 15% 15px 15%;
    border-bottom: 1px solid #e0e0e0;
    margin-top: -30px;
    //margin-bottom: 15px;
    margin-left: -15%;
    margin-right: -15%;
    background-color: white;
    //background:linear-gradient(white, transparent);
    @media(max-width: 991px) {
      padding: 30px 15px 15px 15px;
      background-color: transparent;
      border: 0;
      .profile-picture {
        display: block !important;
      }
      .name-url-holder {
        text-align: center;
        width: 100% !important;
        margin-left: 0 !important;
        display: block !important;
        .name {
          font-size: 175% !important;
        }
        .url {
          font-size: 85% !important;
        }
      }
    }

    .profile-picture {
      padding: 5px;
      margin-top: -150px;

      .pic {
        border: 3px solid #fafafa;
        width: 300px;
        height: 300px;
        background-size: cover !important;
        background-position: center center !important;
        @media(max-width: 991px) {
          width: 250px;
          height: 250px;
          margin: 0 auto !important;
        }
      }
      display: inline-block;
      vertical-align: top;
    }
    .name-url-holder {
      margin-left: 15px;
      width: calc(100% - 300px - 40px);
      display: inline-block;
      vertical-align: top;

      .url {
        margin-top: -5px;
        a {
          text-decoration: none;
          color: grey;
        }
        margin-bottom: 15px;

      }

      .name {
        font-weight: bold;
        font-size: 225%;
        margin-bottom: 5px;
      }
    }
  }

  .content {

    //@media(min-width: 992px) {
    //  margin: 0 -15%;
    //  padding: 15px 15% 15px 15%;
    //  border-top: 1px solid #f0f0f0;
    //  border-bottom: 1px solid #f0f0f0;
    //  .tab-left {
    //    display: inline-block;
    //    width: 305px;
    //    vertical-align: top;
    //  }
    //  .tab-right {
    //    border-left: 1px solid #f0f0f0;
    //    padding-left: 17px;
    //    margin-left: 3px;
    //    display: inline-block;
    //    width: calc(100% - 330px);
    //    vertical-align: top;
    //
    //  }
    //}
    //
    //@media(max-width: 991px) {
    //  .tab-left {
    //    display: none;
    //  }
    //  .tab-right {
    //    .tab-pane {
    //      display: block;
    //    }
    //  }
    //}
    //
    //.nav-tabs {
    //  border-bottom: 0;
    //  li {
    //    float: none;
    //
    //    a {
    //      border: 0;
    //      color: #aaaaaa;
    //      font-size: 125%;
    //      transition: 0.25s all;
    //      &:hover {
    //        color: black;
    //      }
    //    }
    //  }
    //  li.active {
    //    a {
    //      color: black;
    //      border: 0;
    //      font-weight: bold;
    //      background-color: transparent;
    //      &:hover {
    //        color: black;
    //        background-color: #f0f0f0;
    //        border: 0;
    //      }
    //    }
    //  }
    //}
    //.tab-content {
    //  padding-top: 10px;
    //  .tab-title {
    //    font-weight: bold;
    //    font-size: 125%;
    //    color: grey;
    //  }
    //  //border-top:1px solid #e0e0e0;
    //  //border-bottom:1px solid #e0e0e0;
    //  //background-color:#f0f0f0;
    //  margin-bottom: 20px;
    //  min-height: 400px;
    //  //margin:0 -12.5%;
    //  //padding:0 12.5% ;
    //
    //  .card-title {
    //    margin-top: 0 !important;
    //    padding-top: 20px !important;
    //  }
    //  //background-color:white;
    //  //padding:0 15px;
    //  //padding-bottom:15px;
    //  //border:1px solid #ddd;
    //  //border-top:0;
    //}

    .info {

      //background-color:#f0f0f0;
      padding-bottom: 20px;
      //margin-top: -14px;

      .description {
        //font-size: 95%;
        //background-color:#f0f0f0;
        //padding:10px;
      }

      .contact-channel {
        //font-size: 90%;
        margin-bottom: 5px;
        img {
          vertical-align: middle;
          display: inline-block;
          height: 25px;
          //margin-right:5px;
        }

        span {
          height: 25px;
          padding: 3px 10px;
          display: inline-block;
          font-weight: bold;
          vertical-align: middle;
          width: calc(100% - 27px);
          margin-left: -3px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          &.whatsapp-color {
            background-color: #2C910A;
            color: white;
          }

          &.facebook-color {

            color: white;
          }

          &.youtube-color {
            color: white;
            background-color: #AF2210;
          }

          &.wechat-color {
            color: white;
            background-color: #049E07;
          }

          &.twitter-color {
            color: white;
            background-color: #16A0C1;
          }

          &.phone-color {
            color: white;
            background-color: #4E3068;
          }

        }
      }
    }

    .item_listing {
      margin-top: -15px;
      .no-result {
        padding: 7px 15px;
      }
    }
  }

  .contact-channels {
    .contact {
      //font-size: 90%;
      margin-bottom: 5px;
      img {
        vertical-align: middle;
        display: inline-block;
        height: 40px;
        //margin-right:5px;
      }

      span {
        height: 40px;
        padding: 10px 10px;
        display: inline-block;
        font-weight: bold;
        vertical-align: middle;
        width: calc(100% - 42px);
        margin-left: -3px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &.whatsapp-color {
          background-color: #2C910A;
          color: white;
        }

        &.facebook-color {

          color: white;
        }

        &.youtube-color {
          color: white;
          background-color: #AF2210;
        }

        &.wechat-color {
          color: white;

          background-color: #049E07;
        }

        &.twitter-color {
          color: white;
          background-color: #16A0C1;
        }

        &.phone-color {
          color: white;
          background-color: #4E3068;
        }

      }
    }
  }
  .store-branches {
    .branch {
      margin-bottom: 30px;
      transition: 0.25s all;
      outline: 1px solid #eee;

      .location-map {
        margin-bottom: 0;
      }
      .branch-address {
        padding: 15px 15px;
        font-weight: bold;
        text-transform: uppercase;
      }

      &:after {
        content: '';
        display: inline-block;
        width: calc(100% - 30px);
        height: 10px;
        background: url('/img/holder-shadow.png') no-repeat 0 center;
        background-size: 100% auto;
        position: absolute;
        left: 15px;
        bottom: 20px;

        @media(max-width: 767px) {
          //bottom: 5px;
        }
      }

      &:hover {
        outline: 1px solid grey;
      }
    }
  }
}

#marketplace {
  .data-loaded {
    font-size: 100%;
    color: grey;
    margin-bottom: 15px;
    .loaded {
      font-weight: bold;
      margin-right: 5px;
      font-size: 125%;
    }
  }

  .selected-category {
    color: grey;
    //font-size:125%;
    span {

      //color:black;
      font-weight: bold;
    }
  }
  #item_collection {

    .category-choose {
      display: inline-block;
      margin-right: 15px;
      //margin-bottom:15px;
      a {
        //color:black;
        //font-weight:bold;
      }
    }
    .panel-group {
      .panel {
        border: 0;
        box-shadow: none;
        .panel-heading {
          background-color: transparent;
        }
        .panel-body {
          border: 0;
          padding: 0;
        }
      }

    }
    .category-list {
      a {
        text-decoration: none;
      }
      .category {
        &:after {
          content: '';
          display: inline-block;
          width: calc(100% - 30px);
          height: 10px;
          background: url('/img/holder-shadow.png') no-repeat 0 center;
          background-size: 100% auto;
          position: absolute;
          left: 15px;
          bottom: 5px;

          @media(max-width: 767px) {
            //bottom: 5px;
          }
        }
        margin-bottom: 15px;
        //text-align: center;
        border: 1px solid #f0f0f0;

        .icon {
          width: 60px;
          display: inline-block;
          vertical-align: middle;
          height: 60px;
          padding: 5px;
          margin-right: 15px;
          background-color: #f0f0f0;

        }
        .description {
          display: inline-block;
          height: 60px;
          vertical-align: middle;
          line-height: 60px;
        }
      }

    }
  }
}

.category-list {

  a {
    text-decoration: none;
    .category {
      transition: 0.25s all;
      margin-bottom: 15px;
      @media(min-width: 768px) {
        //filter:grayscale(100%);
        //&:hover {
        //  filter:none;
        //}
      }
      border: 1px solid #f0f0f0;
      &:after {
        content: '';
        display: inline-block;
        width: calc(100% - 30px);
        height: 10px;
        background: url('/img/holder-shadow.png') no-repeat 0 center;
        background-size: 100% auto;
        position: absolute;
        left: 15px;
        bottom: 5px;

      }
      &:hover {
        border-color: grey;
      }
      .icon {
        //background-color:red;
        img {
          margin: 10% 30%;
          max-width: 40%;
          border-radius: 50%;
          background-color: rgba(255, 255, 255, 0.45);
        }
        //border-bottom:1px solid #f0f0f0;
      }
      .counter {
        position: absolute;
        background-color: #f0f0f0;
        color: black;
        font-weight: bold;
        height: 50px;
        //width:50px;
        padding: 0 15px;
        min-width: 50px;
        line-height: 50px;
        vertical-align: middle;
        margin-top: -25px;
        right: 30px;
        //border-radius:50%;
        text-align: center;

      }
      .description {
        background-color: white;
        padding: 15px 15px;
        p {
          margin-bottom: 0;
          &.title {
            font-size: 125%;
            color: black;
            font-weight: bold;
          }
          &.subtitle {
            color: grey;
          }
        }

      }
    }
  }

}

.background-cover {
  background-size: cover !important;
}

.background-center {
  background-position: center center !important;
}

.padding-top-100 {
  padding-top: 100%;
}

.padding-15-50 {
  padding: 15% 50%;
}

.margin-bottom-30 {
  margin-bottom: 30px;;
}

.form-holder {
  margin-top: 20px;
  .section {
    padding-top: 30px;
    &:not(:first-child) {
      border-top: 1px solid #e0e0e0;
    }
    padding-bottom: 30px;
    .title {
      margin-bottom: 30px;
      .main {
        color: #003569;
        font-size: 150%;
        font-weight: bold;
        margin-bottom: 10px;
      }
      .sub {
        margin-bottom: 10px;
      }
      .note {
        &:before {
          content: "* ";
        }
        font-size: 90%;
        color: grey;
        margin-bottom: 10px;
      }
    }
    .body {

    }
  }
}

.product-speciality-form {
  .speciality-icon {
    padding-top: 100%;
    background-size: cover !important;
    background-position: center center !important;
  }
}

.fashemag-list {
  a {
    text-decoration: none;

    .fashemag-container {
      //padding: 5px;
      border: 1px solid #d0d0d0;
      &:hover {
        border: 1px solid grey;
      }
      margin-bottom: 30px;
    }

    .container-pic {
      div {
        background-size: cover !important;
        background-position: center center !important;
        background-repeat: no-repeat !important;
        padding: 30% 50%;
      }
    }

    .container-content {
      .title {
        color: black;
        font-weight: bold;
      }
      .subtitle {
        color: grey;
        font-size: 90%;
        margin-top: -5px;
        height: 20px;
      }
      background-color: white;
      padding: 10px;
      width: 100%;
      vertical-align: top;
      height: 65px;

    }
  }
}

.category-selector {
  background-color: #f0f0f0;
  border: 0;
  border-radius: 0;
  color: black;

  a {
    color: grey;
  }
}

.new-entry {
  .loading {
    position: absolute;
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    zoom: 1;
    top: 0;
  }
}

iframe.instagram-media.instagram-media-rendered {
  max-width: 9999999px !important;
}

#specialities_select {
  .checkbox {
    padding: 5px;
    &:hover {
      background-color: #f0f0f0;
    }
    border-radius: 5px;
  }
  label {
    width: 100%;

  }
  .speciality-select-box {

    .speciality-thumbnail {
      display: inline-block;
      width: 60px;
      padding-top: 60px;
      vertical-align: top;
      margin-right: 10px;
    }

    .speciality-details {
      padding-top: 7px;
      width: calc(100% - 60px - 15px);
      vertical-align: top;
      display: inline-block;
      .name {
        color: black;
        margin-bottom: 5px;
      }
      .desc {
        font-size: 0.9em;
        color: grey;
      }
    }
  }
}

.tab-group {
  padding: 15px 0;
  .tab {
    //padding: 7px 0;
    .tab-left {
      padding-top: 8px;
      .tab-section-title {
        text-align: right;
        color: black;
        font-size: 125%;
        font-weight: bold;
        //background-color:#f0f0f0;

        background: -moz-linear-gradient(right, #f0f0f0, transparent);
        background: -o-linear-gradient(right, #f0f0f0, transparent);
        background: -webkit-linear-gradient(right, #f0f0f0, transparent);
        background: linear-gradient(right, #f0f0f0, transparent);
        padding: 7px 15px 7px 0;
      }
    }
    .tab-right {
      padding-top: 15px;
      .tab-title {
        font-weight: bold;
        font-size: 125%;
        color: grey;
        margin-bottom: 7px;
      }
      .tab-body {
        margin-bottom: 15px;
      }
    }

  }

  @media(max-width: 767px) {
    .tab {
      padding: 0;
      .tab-left {
        //display: none;
        .tab-section-title {
          text-align: left;
          background-color: #f0f0f0;
          color: grey;
          font-size: 0.85em;
          text-transform: uppercase;
          margin-left: -15px;
          padding-left: 15px;
          margin-right: -15px;
          padding-right: 15px;
        }
      }
    }

  }

  @media(min-width: 768px) and (max-width: 991px) {
    .tab {
      .tab-left {
        display: inline-block;
        width: 155px;
        vertical-align: top;
      }
      .tab-right {
        border-left: 1px solid #f0f0f0;
        padding-left: 17px;
        margin-left: 3px;
        display: inline-block;
        width: calc(100% - 180px);
        vertical-align: top;
      }
    }
  }

  @media(min-width: 992px) {
    .tab {
      .tab-left {
        display: inline-block;
        width: 305px;
        vertical-align: top;
      }
      .tab-right {
        border-left: 1px solid #e0e0e0;
        padding-left: 17px;
        margin-left: 3px;
        display: inline-block;
        width: calc(100% - 330px);
        vertical-align: top;

      }
    }

  }

}

.product-gallery {
  .zoom-in-notice {
    color: grey;
  }
  .preview-large {
    position: relative;
    margin-bottom: 7px;
    width: 100%;
    padding-bottom: 100%;
    outline: 1px solid #d0d0d0;
    .preview-container {
      position: absolute;
      width: 100%;
      height: 100%;
      text-align: center;
      vertical-align: middle;
      overflow: hidden;
      .preview-image {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }

  .preview-list {
    .image {
      outline: 1px solid #d0d0d0;
      margin-bottom: 15px;

      transition: 0.25s all;
      @media(min-width: 768px) {
        opacity: 0.5;
        &:hover {
          opacity: 1;
        }
        &.active {
          //outline: 2px solid #0f74a8;
          opacity: 1 !important;
        }
      }
    }
  }

}

.photo-rect {
  padding: 0 0 50% 0;
}

.product-info {
  .short-desc {
    margin-bottom: 15px;
    background-color: #f0f0f0;
    padding: 15px;

    .read-more-btn {

    }
  }
}

.product-more-info {
  @media(min-width: 768px) {
    border-top: 1px solid #e0e0e0;
  }
  margin-left: -50%;
  margin-right: -50%;
  padding-left: 50%;
  padding-right: 50%;
}

.has-border {
  border: 1px solid #f0f0f0;
}

.speciality-show {
  &:hover {
    .speciality-icon {
      background-color: #e0e0e0;
    }
  }
  width: 100px;
  vertical-align: top;
  margin-right: 15px;
  display: inline-block;
  margin-bottom: 15px;
  .speciality-icon {
    background-color: #f0f0f0;
    border-radius: 15px;
    img {
      width: 100%;
    }
    margin-bottom: 5px;
  }
  .speciality-name {
    color: black;
    font-size: 0.9em;
    text-align: center;
  }
}

.speciality-showing {
  margin-right: 15px;
  border-radius: 40px;
  background-color: white;
  box-shadow: 0 0 1px #d0d0d0;
  padding: 5px 15px 5px 5px !important;
  color: grey;
  transition: 0.25s all;
  margin-bottom: 15px;
  &:hover {
    //background-color: #e0e0e0;
    color: black;
  }
  .speciality-icon {
    display: inline-block;
    vertical-align: middle;
    //position: absolute;
    //margin-top: -50px;
    background-color: white;
    border-radius: 50%;
    //box-shadow: 0 0 5px grey;
    img {
      height: 50px;
      border-radius: 50%;
    }

  }
  .speciality-desc {
    display: inline-block;
    //margin-left:-50px;
    vertical-align: middle;
    font-weight: bold;
    line-height: 50px;
    margin-left: 5px;
    padding-right: 15px;
    padding-left: 10px;
  }
}

.breadcrumb {
  background-color: transparent;
  border-bottom: 1px solid #f0f0f0;
  a {
    color: grey;
  }
  li:not(:first-child) {
    &:before {
      content: "> " !important;
    }
  }
}

.hide-if-not-only:not(:only-child) {
  display: none;
}

#address_list_holder {
  margin-top: 15px;

  li {
    &:hover {
      background-color: #e0e0e0;
      transition: 0.25s all;
    }
    margin-bottom: 5px;
    background-color: #f0f0f0;
    padding: 10px 5px;
    div {
      vertical-align: top;
      display: inline-block;
      &:first-child {
        width: 25px;
      }
      &:nth-child(n+2) {
        width: calc(100% - 30px);
      }
    }
  }

}

.catalog {
  .catalog-title {
    font-size: 1.75em;
    font-weight: bold;
    margin-bottom: 30px;
  }
  margin-bottom: 30px;
  .nav-tabs {
    margin-bottom: 15px;
    //border:0;
    li {
      margin-bottom: 0;
      &.active {
        a {
          background-color: #f0f0f0;
          color: black;
          font-weight: bold;
          border: 0;
          border-radius: 0;

        }
      }

      a {
        border: 0;
        &:hover {

        }

        text-transform: uppercase;
      }
    }

  }
  .tab-content {
    //background-color: white;
    //padding: 15px;
    //border: 1px solid #ddd;
    //border-radius:4px;
  }

  a {
    text-decoration: none;
  }
  .catalog-item {
    background-color: white;
    border: 1px solid #e0e0e0;
    &:hover {
      background-color: #f0f0f0;
    }
    padding: 15px;
    border-radius: 5px;
    transition: 0.25s all;
    margin-bottom: 15px;
    .catalog-icon {
      zoom: 1.2;
      display: inline-block;
      width: 50px;
      height: 50px;
      margin-right: 10px;
      vertical-align: middle;
    }
    .catalog-description {
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 77px);
      .main_desc {
        font-size: 1.25em;
        color: black;
        font-weight: bold;
        margin-bottom: 0;
      }
      .sub_desc {
        margin-bottom: 0;
        color: grey;
        font-size: 0.9em;
      }

    }
  }
}

.sharethis-holder {
  margin-bottom: 15px;

  @media(max-width: 767px) {
    .sharethis-inline-share-buttons {
      text-align: center !important;
    }
  }
}

.article-banner-holder {
  margin-top: -20px;

  @media(min-width: 1200px) {
    margin: 0 -12% 0 -12% !important;
  }
  @media (max-width: 1199px) {
    margin: 0 -15px 0 -15px;
    width: calc(100% + 30px);
  }
  .banner {
    padding-top: 27%;
    background-size: cover !important;
    background-position: center center !important;
    border-radius: 0;
    @media(max-width: 767px) {
      padding-top: 220px;
    }
  }
}

.single-page {
  .page-title {
    margin-bottom: 30px;
  }

  .sub-section {
    .title {
      color: #003569 !important;
      font-size: 120%;
      font-weight:bold;
      margin-bottom:15px;
    }
    .body {

    }
    margin-bottom: 15px;
  }

}