#marketplace_category_list {
  @media(max-width: 767px) {
    .card-type-title {
      text-align: center;
      margin-top: 15px !important;
    }
  }

  @media(min-width: 768px) {
    .card-type-title {
      //text-align:center;
      margin-top: 30px !important;
    }
  }

  @media(min-width: 768px) and (max-width: 991px) {
    //position: fixed;
    //width: calc(33.33333% - 30px);
    //margin-left: -15px;
  }
  @media(min-width: 992px) and (max-width: 1199px) {
    //position: fixed;
    //width: calc(33.33333% - 30px);
    //margin-left: -15px;
  }

  @media(min-width: 1200px) {
    //position: fixed;
    //width: calc(20.833333% - 30px);
    //margin-left: -15px;
  }

  #carousel {
    height: 150px;
    position: relative;

    .carousel-arrow.left {
      background: no-repeat center/cover url(https://d1v0qqy8316222.cloudfront.net/assets/icon-previous-89117c6fbc4166841173b0a858a078f4a4a78140b8fcef959309e15ca260ec8b.png);
      left: 15px;
    }
    .carousel-arrow.right {
      background: no-repeat center/cover url(https://d1v0qqy8316222.cloudfront.net/assets/icon-next-e707009edfbcd543e9214de4f1fd956f3b43b7bed7b7a494e736a1318b4df394.png);
      right: 15px;
    }
    .carousel-arrow {
      display: block;
      height: 29px;
      margin: 50px 0;
      position: absolute;
      width: 22px;
    }

    ul {
      margin-left: 10%;
      overflow-x: scroll;
      overflow-y: hidden;
      padding: 0;
      position: absolute;
      text-align: center;
      top: 0;
      white-space: nowrap;
      width: 80%;
      -webkit-overflow-scrolling: touch;
      li {
        &:hover {
          background: url(https://d1v0qqy8316222.cloudfront.net/assets/buy/carousel-hover-6fdbfb59a0977caab54d13811ce5c71c76d84aacd987905a0b854822c0e11961.png) center/contain no-repeat;
        }
        display: inline-block;
        height: 126px;
        margin: 5px 25px 5px 0;
        text-align: center;
        width: 104px;
        a {
          color: #666666;
          display: inline-block;
          font-size: 0.8em;
          font-weight: 600;
          padding: 70px 0;
          text-decoration: none;
          -webkit-transition: 0.2s;
          transition: 0.2s;
          width: 104px;
        }
      }
    }
  }

}

.category-holder {
  @media(min-width: 768px) {
    img {
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
      transition: 0.5s all;
    }
  }

  &:hover {
    background-color:#f0f0f0;
    img {
      -webkit-filter: none;
      filter: none;
    }
  }
}


#category_list {
  .panel {
    box-shadow:none;
    border:0;
    border-radius:0;
    .panel-heading {
      padding:10px 0;
      font-weight: bold;
      color: grey;
      border-radius:0;
      border:0;
      background-color:transparent;
      h4 {
        font-size: 150%;
        a {
          text-decoration: none;
        }
      }
    }
    .panel-body {
      background-color:#f0f0f0;
    }
  }
}