@media (max-width: 991px) and (min-width: 768px) {
  div#section-navigator {
    .list-group-item {
      //width:calc(33.3333333% - 13px);
      display: inline-block;
      border-radius: 4px !important;
      text-align: center;
    }

    .list-group-item:nth-child(n+2) {
      //margin-left:15px;
    }
  }

  #store_page_banner {
    .store_banner {
      margin-top: -20px;
      margin-left: calc(-32px - 8.33333333%);
      width: calc(100% + 32.5px + 8.333333% + 32.5px + 8.333333%);
      padding-top: 22.55%;
    }

  }

}