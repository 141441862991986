.no-scroll {
  height: 100%;
  overflow: hidden;
}

#preload {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #0c403b;
  z-index: 99999;
  color: white;
  font-size: 200%;
  margin: 0 -15px;
  display: none;
}

#landing_page {
  //transition: all 1s ease-in-out;
  //transform: scale(1.1);

  .content {
    margin-top: 25px;
    margin-bottom: 25px;
    padding: 25px 0;
    font-size: 17px;
    line-height: 1.5em;
  }

  h2 {
    font-weight: bold;
    text-transform: uppercase;
    padding: 15px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
  }
}

#landing_page_1 {
  background-color: #0C403B;
  margin-left: -15px;
  margin-right: -15px;
  padding: 15px;

  #top_bg {
    background-size: cover !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
  }

  .logo {
    margin-top: 70px;
    height: 360px;
    position: absolute;
    width: 100%;
    img {
      margin: 0 auto;
      width: 300px;
    }
    h1 {
      font-size: 450%;
      color: white;
      font-weight: bold;
    }
  }

  #intro_video {
    color: white;

    #btn-click {
      transition: 0.25s all;
      padding: 15px;
      border: 2px solid white;
      margin: 15px;
      display: inline-block;
      border-radius: 25px;
      margin-top: 25px;

      &:hover {
        background-color: white;
        color: #0C403B;
      }
    }
    #youtube_embed {
      margin-top: 25px;
      display: none;
    }
  }

  #featured_brand {
    color: white;

    #brands_list {
      li {
        border-radius: 30px;
        padding: 7px;
        margin-bottom: 15px;
        transition: 0.25s all;

        &:hover {
          background-color: #052D28;
        }
      }
      a {
        text-decoration: none;
        color: white;

        .brand_pic {
          height: 50px;
          width: 50px;
          background-size: cover;
          display: inline-block;
          margin-right: 5px;
          vertical-align: middle;
          font-weight: bold;
          background-size: cover !important;
          background-position: center center !important;
          background-repeat: no-repeat !important;
        }

        .brand_info {
          display: inline-block;
          width: calc(100% - 61px);
          line-height: 1.3em;
          height: 50px;
          vertical-align: middle;
          padding: 5px;

          .brand_desc {
            font-size: 70%;
            color: #dadada;
          }
        }
      }
    }
  }

}

#landing_page_2 {
  margin: 0 -15px 0 -15px;
  padding: 0 15px;
  background-color: #fafafa;
  h2 {
    border-color: black;
  }
  #mid_bg {
    width: calc(100% + 45px);
    margin-left: -20px;
    margin-right: -20px;
    margin-top: -5px;
    margin-bottom: 50px;
  }

  #features {

    .feature {
      margin-bottom: 50px;

      .sub {
        font-size: 14px;
        color: grey;
        margin-top: -7px;
        margin-bottom: 35px;
        line-height: 14px;
      }
      .frame {
        margin: 0 -2px;

      }
      .screenshot {
        width: 100%;
      }
    }
  }

  #recruitment {
    #btn-click {
      text-decoration: none;
      color: #009688;
      padding: 15px;
      border: 2px solid #009688;
      margin: 15px;
      display: inline-block;
      border-radius: 25px;
      transition: 0.25s all;

      &:hover {
        background-color: #009688;
        color: white;
      }
    }
  }
}

#landing_page_3 {
  margin: 0 -15px 0 -15px;
  padding: 0 15px;
  background-color: #1d164e;
  margin-top: -100px;

  h2 {
    color: white;
  }

  .content {
    color: white;
  }
  #bot_bg {
    height: 744px;
    margin: 0 -15px 0 -15px;
    background-size: cover !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
  }

  .sub {
    font-size: 14px;
    color: grey;
    margin-top: -7px;
    margin-bottom: 25px;
    line-height: 14px;
  }

  #tshirt_slideshow {
    .carousel-img {
      padding-bottom: 100%;
      background-size: cover !important;
      background-position: center center !important;
      background-repeat: no-repeat !important;
    }

    margin-bottom: 25px;
  }

  #btn-click {
    transition: 0.25s all;
    text-decoration: none;
    color: white;
    padding: 15px;
    border: 2px solid white;
    margin: 15px;
    display: inline-block;
    border-radius: 25px;

    &:hover {
      background-color: white;
      color: #1d164e;
    }
  }

  #social_channel {
    .icons {
      a {
        text-decoration: none;
        font-size: 300%;
        color: white;

        &:hover {
          color: #13BBC4;
        }
      }
    }
  }

}

nav.landing {
  margin-bottom: -70px;
}

#fashemag_intro {
  #fashemag_list {

    margin-top: -25px;
    margin-bottom: -25px;
    li {
      transition: 0.25s all;
      padding: 10px;
      border-radius: 5px;
      a {
        display: flex;
      }
    }
    li:hover {

      background-color: #0B0A26;

    }

    li {
      margin-bottom: 7px;
    }
    a {
      text-decoration: none;
    }
    #pic {
      display: inline-block;
      div {
        background-size: cover !important;
        background-position: center center !important;
        background-repeat: no-repeat !important;
        height: 75px;
        width: 75px;

      }
    }

    #content {
      #title {
        color: white !important;
        font-weight: bold;
        font-size: 1.1em;
      }
      #subtitle {
        color: grey;
        font-size: 90%;
        margin-top: -5px;
        height: 20px;
      }
      display: inline-block;
      margin-left: 9px;
      width: calc(100% - 75px - 15px);
      vertical-align: top;
      height: 65px;
      margin-top: 10px;

    }
  }
}