// STORE PROFILE PAGE SCSS

#store_banner {
  //margin:-20px -15px 0 -15px;
  margin-top:-20px;
  //width:calc(100% + 30px);
  @media(max-width:1199px){
    margin:-20px -15px 0 -15px;
    width:calc(100% + 30px);
  }
  .banner {
    padding-top: 27%;
    background-size: cover !important;
    background-position: center center !important;
    border-radius: 0;
    @media(max-width: 767px) {
      padding-top: 220px;
    }
  }

}

#store_heading {
  //border:1px solid #f0f0f0;
  //background-color:#fbfbfb;
  padding: 30px 50px 15px 50px;
  margin-top: -30px;
  margin-bottom:15px;

  @media(max-width: 991px) {
    padding:30px 15px 15px 15px;
    background-color:transparent;
    border:0;
    .profile-picture {
      display:block !important;
    }
    .name_url_holder {
      text-align:center;
      width:100% !important;
      margin-left:0 !important;
      display:block !important;
      .name {
        font-size:175% !important;
      }
      .url {
        font-size:85% !important;
      }
    }
  }


  .profile-picture {
    padding: 5px;
    margin-top: -150px;

    .pic {
      border: 3px solid #fafafa;
      width: 300px;
      height: 300px;
      background-size: cover !important;
      background-position: center center !important;
      @media(max-width: 991px) {
        width: 250px;
        height: 250px;
        margin:0 auto !important;
      }
    }
    display: inline-block;
    //border:2px solid white;
    vertical-align: top;
  }
  .name_url_holder {
    margin-left: 15px;
    width: calc(100% - 300px - 40px);
    display: inline-block;
    vertical-align: top;

    .url {
      margin-top: -5px;
      a {
        text-decoration: none;
        color: grey;
      }
      margin-bottom:15px;

    }

    .name {
      font-weight: bold;
      font-size: 225%;
      margin-bottom:5px;
    }
  }
}

#store_content {

  .info {

    //background-color:#f0f0f0;
    padding-bottom: 20px;
    //margin-top: -14px;

    .description {
      //font-size: 95%;
      //background-color:#f0f0f0;
      //padding:10px;
    }

    .contact-channel {
      //font-size: 90%;
      margin-bottom: 5px;
      img {
        vertical-align: middle;
        display: inline-block;
        height: 25px;
        //margin-right:5px;
      }

      span {
        height: 25px;
        padding: 3px 10px;
        display: inline-block;
        font-weight: bold;
        vertical-align: middle;
        width: calc(100% - 27px);
        margin-left: -3px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &.whatsapp-color {
          background-color: #2C910A;
          color: white;
        }

        &.facebook-color {

          color: white;
        }

        &.youtube-color {
          color: white;
          background-color: #AF2210;
        }

        &.wechat-color {
          color: white;
          background-color: #049E07;
        }

        &.twitter-color {
          color: white;
          background-color: #16A0C1;
        }

        &.phone-color {
          color: white;
          background-color: #4E3068;
        }

      }
    }
  }

  .item_listing {
    margin-top: -15px;
    .no-result {
      padding: 7px 15px;
    }
  }
}
