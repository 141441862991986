#fashemag {

  #cover_photo {
    padding: 15% 50%;
    background-size:cover !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
  }

  #article_title {
    //border-bottom: 1px solid #d0d0d0;
    margin-bottom: 25px;

    #article_subtitle {
      margin-top:-10px;
      font-size:130%;
      //color:grey;

    }

    #post_tags {
      margin-top: -30px;
      padding-left:10px;
      margin-bottom: 15px;
    }

    #post_date {
      font-size: 85%;
      color: grey;
      margin-bottom: 15px;
      //margin-top:-7px;
    }
  }

  .main_article {

    #article-content {
      //padding:20px;
      //background-color:#f0f0f0;
      img {
        width: 100% !important;
        height: auto !important;
      }

      .embed-responsive {
        margin-bottom:15px;
      }
    }


  }

  .subtitle {
    font-weight: bold;
    text-transform: uppercase;
    color: grey;
    padding: 10px 10px;
    text-align: center;
    //background-color:#f0f0f0;
    margin-bottom: 15px;
  }

  #other_articles {

    li{
      margin-bottom:7px;
    }
    a {
      text-decoration:none;
    }
    #pic {
      display:inline-block;
      div {
        background-size:cover !important;
        background-position: center center !important;
        background-repeat: no-repeat !important;
        height:75px;
        width:75px;

      }
    }

    #content {
      #title{
        color:black;
        font-weight:bold;
      }
      #subtitle{
        color:grey;
        font-size:90%;
        margin-top:-5px;
        height:20px;
      }
      display:inline-block;
      margin-left:5px;
      width: calc(100% - 75px - 10px);
      vertical-align: top;
      height:65px;
      margin-top:10px;

    }
  }
}

#fashemag_list {
  #items {
    a {
      text-decoration:none;
    }
    #container {
      padding:5px;
      border:1px solid #d0d0d0;
      &:hover{
        border:1px solid grey;
      }
      margin-bottom:30px;
    }
    #pic {
      div {
        background-size:cover !important;
        background-position: center center !important;
        background-repeat: no-repeat !important;
        padding-bottom:100%;
      }
    }

    #content {
      #title{
        color:black;
        font-weight:bold;
      }
      #subtitle{
        color:grey;
        font-size:90%;
        margin-top:-5px;
        height:20px;
      }
      background-color: white;
      padding:10px;
      width: 100%;
      vertical-align: top;
      height:65px;

    }
  }
}

#linked_brand {
  margin-bottom:25px;
  a {
    text-decoration: none !important;
    display:inline;
  }

  p{
    display:inline;
  }

  .product_profile_pic {
    height: 25px;
    width: 25px;
    margin-bottom: -7.5px;
    margin-left: 5px;
    margin-top: -2.5px;
    display: inline-block;
    background-size: cover !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    border-radius: 50%;
    border: 1px solid #d0d0d0;
  }
}