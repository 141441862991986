@media (max-width: 767px) {
  .navbar {
    .row {
      margin-left: -30px;
      margin-right: -30px;
    }
  }

  #store-header-md {
    #profile-picture {
      padding-bottom: calc(100% - 10px);
      background-position: center center !important;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      border-radius: 50%;
      margin-top: -70px;
      border: 5px solid #fafafa;

    }

    #store-title {
      text-align: center;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    #store-url {
      text-align: center;
    }
  }

  .sm-screen {
    display: block !important;
  }

  .lg-screen {
    display: none !important;
  }

  h1 {
    //margin-top:5px;
    font-size: 20px;
  }

  h4 {
    font-size: 16px;
    //text-align: left !important;
  }
  .profile-picture-holder {

    padding: 0;
  }

  .store-description {
    font-size: 0.8em;
  }

  #store-detail {
    #store-description.text-center {
      text-align: center !important;
    }
  }

  #product_gallery {

    .col-sm-4:first-child {
      margin-top: -15px !important;
    }

    .product_image_description {
      top: 0 !important;
      height: 100%;
    }

    .product-desc {
      background-color: #f0f0f0;
      padding: 10px 5px;
      margin-top: -15px;
      text-align: center;
      color: black;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);

      p {
        margin: 0;

      }
      #name {
        font-size: 110%;
        font-weight: bold;
        display: block;
      }
      #price {
        font-weight: bold;
      }

    }

  }

  .product_image_description {
    .desc_holder {
      text-align: center;
      font-size: 0.75em !important;
    }
  }

  footer {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
    .col-sm-3 {
      text-align: center !important;
      margin-top: 10px;
    }

    a {
      margin: 0 5px;

    }
  }

  #large_preview {
    display: none;
  }
  .form-group {
    label {
      i {
        display: none !important;
      }
      span {
        display: block;
      }
    }
  }

  #profile-picture {
    margin-left: 0 !important;
  }
  #profile-picture-username {
    zoom: 0.75;
    h1 {
      margin-bottom: 5px;
      margin-top: 7px;
    }
    #store-name-display {
      display: inline-block;
      vertical-align: middle;
      margin-left: -10px;
      max-width: 72%;
      word-break: break-all;
    }

    #profile-picture {
      margin-right: 10px !important;

    }
  }

  #product-image-list {
    .col-sm-3 {
      margin-bottom: 15px;
    }
    .col-sm-3:last-child {
      margin-bottom: 0 !important;
    }

  }

  #contact-information {
    img {
      display: inline-block !important;
    }
    .info-title {
      display: none;
    }

    #social-list {
      span {
        display: block !important;
      }
    }

  }

  .shop-header-sm {
    #profile-picture {
      height: 35px !important;
      width: 35px !important;
    }

    #store-name {
      width: calc(100% - 35px - 14px) !important;
      margin-top: 7px !important;
      font-size: 0.9em !important;
    }
  }

  .home-page-title {
    display: block !important;
    margin-top: 15px !important;
    text-align: center !important;
  }

  .flex-parent {
    display: block;
  }

  #market_place {
    .item-holder {
      .product-image {
        .photo-square {
          width: calc(100% - 2px) !important;
          padding-bottom: 100% !important;
        }
      }
      .product-detail {
        right: 0;
        margin-left: 0;
        flex: 3;
        position: relative;
        height: 100%;
        transition: 0.25s all;
        border-top: 1px solid #efefef;
        zoom: 1;
        width: 100%;
      }

      .product-name {
        border-top: 1px solid #efefef;
      }

      .product-price.sm-screen {
        display: inline-block !important;
        margin-left: 0;
        margin-top: 0;
      }

      .load-more {
        display: none;
      }

    }
  }

  #profile-picture-holder {
    #profile-picture {
      margin-top: 15px;
    }
  }

  div#section-navigator {

    .list-group-item {
      display: inline-block;
      border-radius: 4px !important;
      margin-top: 0 !important;
    }
    span {
      display: none;
    }
  }

  .setting-page-title {
    //border-bottom: 0;
    margin-bottom: 20px;
  }

  .setting-section {
    border-bottom: 0;
    margin-bottom: 10px;
    padding-bottom: 0;
  }

  .out-of-stock {
    right: 25px !important;
  }

  #login_form, #register_form {
    max-width: 100%;
    margin: 0 auto;
    //margin-top:-20px !important;

    .panel-default.panel {
      background-color: #f0f0f0 !important;
      margin-bottom: 0 !important;
    }
  }

  #landing-form {
    .panel {
      padding: 20px;
      //background: transparent;
      //margin-bottom:0;
      zoom: 1;
    }
  }

  #store_page_banner {
    .store_banner {
      margin-top: -20px;
      margin-left: -32px;
      width: calc(100% + 32.5px + 32.5px);
      padding-top: calc(30% + 30px);
    }

  }

  #store_store_info {
    #store_profile_picture {
      margin-top: -60% !important;
    }
  }

  #product-image-carousel {
    margin: 0 -15px 20px -15px;

    .carousel-control {
      display: none;
    }
  }

  .product-product-information {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
  }

  .social-media-sharing {
    //border-top: 1px solid #f0f0f0;
    //padding: 0;
    //padding-top: 15px;
    //background-color: transparent;
    //box-shadow: none;
  }

  #marketplace {
    //.col-xs-6:nth-child(odd) {
    //  padding-right:7.5px;
    //}
    //
    //.col-xs-6:nth-child(even){
    //  padding-left:7.5px;
    //}

    .market_product_image {
      margin-bottom: 15px;
    }

    .market_store_image {
      margin-bottom: 15px;
      padding: 25% 50%;
    }

    .market_product_image_large {
      margin-bottom: 15px;
    }

    .market_desc {
      height: calc(100%);
    }

    .row {
      .col-xs-6:nth-child(odd) {
        padding-right: 7.5px !important;
        .market_desc {
          width: calc(100% - 22.5px);
          height: calc(100% - 15px);
          margin-right: 7.5px;
        }

      }
      .col-xs-6:nth-child(even) {
        padding-left: 7.5px !important;
        .market_desc {
          width: calc(100% - 22.5px);
          height: calc(100% - 15px);
          margin-left: 7.5px;
        }

      }

    }

  }

  #product_image_selection {
    .col-sm-6 {
      margin-bottom: 15px;
    }
    .product-image {
      //border: 1px solid;
      //border-color: #e5e6e9 #dfe0e4 #d0d1d5;
      //border-radius: 5px;

      &.active {
        outline: none;
      }
    }

  }

  #product_product_detail {
    margin-top: 0;
    padding-top: 15px;
  }

  .product-product-pricing {
    padding-top: 0;
  }

  #landing_column {
    margin-top: 0;
    border: 0;
    height: auto;

    #register_form, #login_form {
      height: auto;
    }
  }

  .store_page {
    .store_section_title {
      //margin:0 -15px 10px -15px;
    }

    #store_store_info {
      padding-bottom: 7px !important;

      #store_profile_picture {
        padding-bottom: calc(100% - 8px);
        border: 4px solid #fafafa;
      }

      #store_store_description {
        font-size: 100%;
        //background-color:#f0f0f0;
        //padding:10px;
      }

    }
    #store_item_listing {
      margin-top: 0 !important;

      .store_section_title {
        margin-bottom: 0 !important;
      }

      .store_product_item {
        .product-image {
          margin: 0 -15px 10px -15px;
          margin-bottom: 0;
        }

        .product-desc {
          background-color: rgba(0, 0, 0, 0.35);
          color: white;

        }
      }
    }
  }

  .product-listing-sm {
    margin-bottom: 7px;
    //padding-top:10px;
    //padding-bottom:10px;
    a {
      text-decoration: none;
    }

    &:nth-child(2) {
      //border-top: 1px solid #d0d0d0;
      //padding-top: 5px;
    }

    &:last-child {
      padding-bottom: 10px;
    }
    li {
      //padding:0 15px;
      //background-color:white;
      margin-bottom: 5px;
      border-bottom: 1px solid #d0d0d0;
      .col-xs-5 {
        padding: 5px 15px 10px 15px;

      }
      .col-xs-7 {
        padding: 10px 5px 20px 0;
        width: calc(58.33333333333% - 15px);
        #product-name {
          font-size: 110%;
          font-weight: bold;
          color: black;
        }

        #availability {
          color: #003569;
          font-size: 90%;
        }

        #product-desc {
          color: grey;
          font-size: 90%;
        }

        #price {
          color: green;
          font-weight: bold;
        }

        p {
          margin-bottom: 5px;
        }

      }
    }
  }

  #product_listing {
    ul {
      li#new_item {
        background-color: #e0e0e0;
        border-left: 1px solid #d0d0d0;
        border-right: 1px solid #d0d0d0;
        margin-top: -5px;
        padding: 10px 15px;
        text-align: center;
        &:hover {
          a {
            color: black;
          }

        }
        a {
          display:block;
          text-decoration: none;
          color: dimgrey;

        }
      }
    }
  }

  #auth_page {
    .sub_heading {
      //border-bottom: 1px solid #d0d0d0;
      //padding-bottom:15px;
      //margin-bottom:0;
    }

    #login_panel {
      background-color:transparent;
      border:0;
      padding: 15px;
      margin:0 -15px;

      .section-separator {
        span {
          background-color:#fafafa;
        }
      }
    }
  }

  #landing_page {
    #landing_page_1 {
      .logo {
        margin-top:70px;
        height:330px;
        h1 {
          font-size:35px;
        }
      }
    }

  }

}

